// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  location: {},
  loadingLocation: false,
  loadedLocation: false,
  waypoints: [],
  routes: [],
  connections: [],
  client: {},
  selectedRoute: {},
  chosenRouteId: null,
  sentFeedback: false,
  sentComment: false,
  loadingRoutes: false,
  loadedRoutes: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_LOCATION:
      return {
        ...state,
        loadingLocation: true,
        loadedLocation: false,
        location: {},
        waypoints: [],
        client: {},
        routes: [],
        connections: [],
        selectedRoute: {},
        chosenRouteId: null,
        sentFeedback: false,
        sentComment: false,
      };
    case actionConstants.LOADED_LOCATION:
      return {
        ...state,
        loadingLocation: false,
        loadedLocation: true,
        location: action.location,
        waypoints: action.waypoints,
        client: action.client,
        connections: action.connections,
      };
    case actionConstants.SET_SELECTED_ROUTE:
      return {
        ...state,
        selectedRoute: action.payload,
        chosenRouteId: null,
        sentFeedback: false,
        sentComment: false,
      };  
    case actionConstants.SET_CHOSEN_ROUTE:
      return {
        ...state, chosenRouteId: action.payload,
      };  
    case actionConstants.SENT_FEEDBACK:
      return {
        ...state, sentFeedback: true,
      }; 
    case actionConstants.SENT_COMMENT:
      return {
        ...state, sentComment: true,
      };  
    case actionConstants.LOADING_ROUTES:
      return {
        ...state, loadingRoutes: true, loadedRoutes: false
      };
    case actionConstants.LOADED_ROUTES:
      return {
        ...state, loadingRoutes: false, loadedRoutes: true, routes: action.payload
      };   
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getLocation = ({
  location, loadingLocation, loadedLocation, waypoints, client, routes, connections, selectedRoute, chosenRouteId, sentFeedback, sentComment, loadingRoutes, loadedRoutes,
}) => ({
  location,
  loadingLocation,
  loadedLocation,
  waypoints,
  client,
  routes,
  connections,
  selectedRoute,
  chosenRouteId,
  sentFeedback,
  sentComment,
  loadingRoutes,
  loadedRoutes,
});
