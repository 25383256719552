export const SET_ACCESSIBILITY = 'SET_ACCESSIBILITY';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const LOADING_LOCATION = 'LOADING_LOCATION';
export const LOADED_LOCATION = 'LOADED_LOCATION';
export const LOADING_ROUTES = 'LOADING_ROUTES';
export const LOADED_ROUTES = 'LOADED_ROUTES';

export const SET_SELECTED_ROUTE = 'SET_SELECTED_ROUTE';
export const SET_CHOSEN_ROUTE = 'SET_CHOSEN_ROUTE';
export const SENT_FEEDBACK = 'SENT_FEEDBACK';
export const SENT_COMMENT = 'SENT_COMMENT';
