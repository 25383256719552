import { combineReducers } from 'redux';
import general, * as fromGeneral from './generalReducer';
import location, * as fromLocation from './locationReducer';

export default combineReducers({
  general,
  location,
});

export const getGeneral = (state) => fromGeneral.getGeneral(state.general);
export const getLocation = (state) => fromLocation.getLocation(state.location);
