import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { staticTranslate } from '../Tools';

const CustomModal = ({show, onHide, children, primaryColor, language}) => {

  const renderModal = () => {
    return (
      <div>
        <Modal.Body style={{backgroundColor: 'white', marginTop: -1, marginBottom: -1, borderBottom: 'none'}}>
          {children}
        </Modal.Body> 
      </div>
    )
  }
  
  return (
    <Modal show={show} fullscreen={true} onHide={onHide} animation={false}>
      {renderModal()}     
    </Modal>
  )
}

export default CustomModal;