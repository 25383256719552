import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking, faWheelchair, faBars } from '@fortawesome/free-solid-svg-icons';
import ReactFlagsSelect from 'react-flags-select';
import { staticTranslate } from '../Tools';
import MenuModal from './MenuModal';

const Header = ({accessibility, setAccessibility, primaryColor, language, setLanguage, client}) => {
  let countriesOptions = ["US", "ES"]
  let countriesLabels = {"US": "English", "ES": "Espanyol"}
  if (client.language === 'GB') {
    countriesOptions = ["GB", "ES"]
    countriesLabels = {"GB": "English", "ES": "Espanyol"}
  }
  
  return (
    <Row style={{marginTop: 15}}>
      <Col className='text-left'>
        <ReactFlagsSelect
          selected={language}
          countries={countriesOptions}
          customLabels={countriesLabels}
          placeholder="Select Language"
          onSelect={code => setLanguage(code)}
          selectButtonClassName="menu-flags-button"
        />
      </Col>
      <Col className='text-right'>
        <Row>
          <Col></Col>
          <Col style={{paddingTop: 4, width: 20, textAlign: 'right', paddingRight: 0}}>
            <FontAwesomeIcon icon={faWalking} style={{fontSize: 20}} />
          </Col>
          <Col style={{maxWidth: 80}}>
            <Form>
              <Form.Check 
                type="switch"
                id="accessibility-switch"
                checked={accessibility}
                onChange={(event) => setAccessibility(event.target.checked)}
                isValid={true}
              />
            </Form>
          </Col>
          <Col style={{paddingTop: 4, width: 20, textAlign: 'left', paddingLeft: 0}}>
            <FontAwesomeIcon icon={faWheelchair} style={{fontSize: 20}} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Header;