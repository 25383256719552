export const textStrings = {
  en_us: {
    welcomeTo: 'Welcome to',
    youAreAt: 'You are at',
    whereWouldYouLikeToGo: 'Where would you like to go?',
    selectDestination: 'Select destination',
    start: 'Start',
    menu: 'Menu',
    close: 'Close',
    selectLanguage: 'Select language',
    searchLocations: 'Search locations',
    quickFilter: 'Quick filter',
    tapToSelect: 'Tap to select',
    minutesAway: 'Minutes away',
    tapToChange: 'Tap to change',
    destination: 'Destination',
    yourRouteIsReady: 'Your route is ready',
    noRouteAvailable: 'Sorry, no route is available',
    estimatedTime: 'Estimated time',
    minute: 'Minute',
    youHaveReachedYourDestination: 'You have reached your destination!',
    howEasilyDidYouFindYourDestination: 'How easily did you find your destination?',
    thankYou: 'Thank you!',
    thankYouAgain: 'Thank you again!',
    wouldYouLikeToAddAComment: 'Would you like to add a comment?',
    yourFeedbackIsVeryImportantToUs: 'Your feedback is very important to us',
    submit: 'Submit',
    restrooms: 'Restrooms'
  },
  en_gb: {
    welcomeTo: 'Welcome to',
    youAreAt: 'You are at',
    whereWouldYouLikeToGo: 'Where would you like to go?',
    selectDestination: 'Select destination',
    start: 'Start',
    menu: 'Menu',
    close: 'Close',
    selectLanguage: 'Select language',
    searchLocations: 'Search locations',
    quickFilter: 'Quick filter',
    tapToSelect: 'Tap to select',
    minutesAway: 'Minutes away',
    tapToChange: 'Tap to change',
    destination: 'Destination',
    yourRouteIsReady: 'Your route is ready',
    noRouteAvailable: 'Sorry, no route is available',
    estimatedTime: 'Estimated time',
    minute: 'Minute',
    youHaveReachedYourDestination: 'You have reached your destination!',
    howEasilyDidYouFindYourDestination: 'How easily did you find your destination?',
    thankYou: 'Thank you!',
    thankYouAgain: 'Thank you again!',
    wouldYouLikeToAddAComment: 'Would you like to add a comment?',
    yourFeedbackIsVeryImportantToUs: 'Your feedback is very important to us',
    submit: 'Submit',
    restrooms: 'Toilets'
  },
  es: {
    welcomeTo: 'Bienvenido a',
    youAreAt: 'Estás en',
    whereWouldYouLikeToGo: '¿Dónde te gustaría ir?',
    selectDestination: 'Seleccionar destino',
    start: 'Comienzo',
    menu: 'Menú',
    close: 'Cerrar',
    selectLanguage: 'Seleccione el idioma',
    searchLocations: 'Buscar ubicaciones',
    quickFilter: 'Filtro rápido',
    tapToSelect: 'Toque para seleccionar',
    minutesAway: 'minutos de distancia',
    tapToChange: 'Toca para cambiar',
    destination: 'Destino',
    yourRouteIsReady: 'Tu ruta está lista',
    noRouteAvailable: 'Lo sentimos, no hay ruta disponible',
    estimatedTime: 'Hora prevista',
    minute: 'Minuto',
    youHaveReachedYourDestination: '¡Ha llegado a su destino!',
    howEasilyDidYouFindYourDestination: '¿Con qué facilidad encontraste tu destino?',
    thankYou: '¡Gracias!',
    thankYouAgain: '¡Gracias de nuevo!',
    wouldYouLikeToAddAComment: '¿Le gustaría dejar un comentario?',
    yourFeedbackIsVeryImportantToUs: 'Su opinión es muy importante para nosotros',
    submit: 'Entregar',
    restrooms: 'Baños'
  }
}
