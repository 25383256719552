import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const DefaultLayout = ({ children, noNavbar, noFooter }) => {
  
  return (
    <Container fluid>
      {children}
    </Container>
  )
};

export default DefaultLayout;
