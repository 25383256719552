import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, FormControl, Container } from 'react-bootstrap';
import { logEvent } from "firebase/analytics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from "react-router-dom";
import { faSmile, faMeh, faFrown, faMapMarkerAlt, faRetweet, faSpinner, faShareAlt, faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import SelectDestinationModal from '../../components/SelectDestinationModal';
import { analytics } from '../../App';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Logo from '../../components/Logo';
import { autoTranslate, staticTranslate, getPrimaryColor } from '../../Tools';
import { getRoutes } from '../../actions/locationActions';

const forwardArrow = require('../../images/forward_arrow.png')
const moment = require('moment')

const Route = (props) => {

  let {locationCode, fromCode, toCode} = useParams();
  const [comment, setComment] = useState(null);
  const [showStartingModal, setShowStartingModal] = useState(false);
  const [showDestinationModal, setShowDestinationModal] = useState(false);
  const [descriptions, setDescriptions] = useState([])
  const [noAccessibleRoute, setNoAccessibleRoute] = useState(false)
  const [routes, setRoutes] = useState(null)

  useEffect(() => {
    if (locationCode) {
      props.getLocationByCode(locationCode);
    }
  }, [])

  useEffect(() => {
    if (props.location?.id && props.waypoints?.length > 0) {
      props.getRoutes(props.waypoints.find(waypoint => waypoint.waypointCode === fromCode)?.id)
    }
  }, [props.location, props.waypoints])

  useEffect(() => {
    if (locationCode && props?.location.id && props?.routes.length > 0) {
      let current = window.location.href
      let currentArray = current.split('/')
      fromCode = currentArray[5].substring(0, 6)
      toCode = currentArray[6].substring(0, 6)
      let route = props?.routes?.find(route => route.from === props?.waypoints?.find(waypoint => waypoint.waypointCode === fromCode)?.id && route.to === props?.waypoints?.find(waypoint => waypoint.waypointCode === toCode)?.id);
      if (props?.location?.id && fromCode && toCode && route) {
        props.setSelectedRoute(route)
        setRoutes(props.routes)
      }
    }
  }, [props.location, props.routes])

  useEffect(() => {
    if (props.location && props.location.id && props.selectedRoute && props.selectedRoute.id) {
      let current = window.location.href
      let currentArray = current.split('/')
      fromCode = currentArray[5].substring(0, 6)
      toCode = currentArray[6].substring(0, 6)
      if (!fromCode || props.waypoints.find(waypoint => waypoint.id === props.selectedRoute.from).waypointCode !== fromCode && (fromCode !== props.waypoints.find(waypoint => waypoint.id === props.selectedRoute.to).waypointCode) && (toCode !== props.waypoints.find(waypoint => waypoint.id === props.selectedRoute.from).waypointCode)) {
        fromCode = props.waypoints.find(waypoint => waypoint.id === props.selectedRoute.from).waypointCode
      }
      if (!toCode || props.waypoints.find(waypoint => waypoint.id === props.selectedRoute.to).waypointCode !== toCode && (fromCode !== props.waypoints.find(waypoint => waypoint.id === props.selectedRoute.to).waypointCode) && (toCode !== props.waypoints.find(waypoint => waypoint.id === props.selectedRoute.from).waypointCode)) {
        toCode = props.waypoints.find(waypoint => waypoint.id === props.selectedRoute.to).waypointCode
      }
      window.history.pushState('route', 'Title', '/route/' + props.location.locationCode + '/' + fromCode + '/' + toCode);
      document.title = props.location.name + ' | Route chosen | Waysnap'
      logEvent(analytics, 'Route chosen', {location_name: props.location.name, location_id: props.location.id, route_from: props.selectedRoute.from, route_to: props.selectedRoute.to, time: moment().toISOString()});
      props.incrementRouteCount(props.selectedRoute)
      setNoAccessibleRoute(false)

      let getTranslations = async () => {
        let tempDescriptions = [];
        let promises = [];
        props.selectedRoute.connections.map((connectionRef, index) => {
          let connection = props.connections.find(connection => connection.id === connectionRef)
          promises.push(autoTranslate(connection.description, props.language).then(text => text))
        })
        tempDescriptions = await Promise.all(promises).then((data) => data)
        setDescriptions(tempDescriptions)
      }

      getTranslations()
    }
    window.scrollTo(0, -50)
    
  }, [props.selectedRoute, props.language])

  useEffect(() => {
    if (props.location && props.location.id) {
      let validRoute = props.routes.filter(route => props.accessibility ? route.accessibility : true).find(route => route.to === props.selectedRoute.to && route.from === props.selectedRoute.from)
      if (!validRoute) {
        setNoAccessibleRoute(true)
      } else {
        if (validRoute.id !== props.selectedRoute.id) {
          props.setSelectedRoute(validRoute)
        }
        setNoAccessibleRoute(false)
      }
    }
  }, [props.accessibility])

  const setRoute = (waypoint, route) => {
    enableBodyScroll(document.querySelector('#mainBody'))
    props.setSelectedRoute(route)
  }

  const handleOnShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `${props.location.name}`,
          text: `Follow this simple route`,
          url: document.location.href,
        })
        .then(() => {
          console.log('Successfully shared');
        })
        .catch(error => {
          console.error('Something went wrong sharing the blog', error);
        });
    }
  };

  const reverseRoute = () => {
    let confirmed = window.confirm('Are you sure you want to reverse your route?')
    if (confirmed) {
      let current = window.location.href
      let currentArray = current.split('/')
      let currentFrom = currentArray[5].substring(0, 6)
      let currentTo = currentArray[6].substring(0, 6)
      window.location.href = '/route/' + locationCode + '/' + currentTo + '/' + currentFrom
      // let validRoute = props.routes.filter(route => props.accessibility ? route.accessibility : true).find(route => route.from === props.selectedRoute.to && route.to === props.selectedRoute.from)
      // if (!validRoute) {
      //   setNoAccessibleRoute(true)
      // } else {
      //   if (validRoute.id !== props.selectedRoute.id) {
      //     props.setSelectedRoute(validRoute)
      //   }
      //   setNoAccessibleRoute(false)
      // }
    }
  }

  const renderRouteDescription = () => {
    if (noAccessibleRoute) {
      return (
        <Row style={{marginTop: 30, marginBottom: 100}}>
          <Col className='text-center'>
            <span style={{fontWeight: 'bold', fontSize: 18}}>{staticTranslate('noRouteAvailable', props.language)}</span><br />
          </Col>
        </Row>
      )
    }
    return (
      <Row style={{marginTop: 30}}>
        <Col className='text-center'>
          <span style={{fontWeight: 'bold', fontSize: 18}}>{staticTranslate('yourRouteIsReady', props.language)}</span><br />
          <span>{staticTranslate('estimatedTime', props.language)}: {props.selectedRoute.time} {staticTranslate('minute', props.language).toLowerCase()}{props.selectedRoute.time > 1 && 's'}</span>
        </Col>
      </Row>
    )
  }

  const renderStartPoint = () => {
    let startPoint = props.waypoints.find(waypoint => waypoint.id === props.selectedRoute.from)
    return (
      <Row style={{marginTop: 30}}>
        <Col className='text-left'>
          <div style={{paddingTop: 8, paddingBottom: 8, paddingLeft: 16, paddingRight: 16, border: '1px solid #EAEDF3'}} onClick={() => setShowStartingModal(true)}>
            <Row>
              <Col xs={1} style={{paddingTop: 6}}>
                <span style={{fontSize: 24, color: '#68D266'}} >
                  <FontAwesomeIcon icon={faCrosshairs} />
                </span>
              </Col>
              <Col xs={11} style={{paddingLeft: 20}}>
                <span style={{fontSize: 14}}>{staticTranslate('start', props.language)} - {staticTranslate('tapToChange', props.language).toLowerCase()}</span><br />
                <span style={{fontSize: 18}}>{startPoint.name.toUpperCase()}</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    )
  }

  const renderEndPoint = () => {
    let endPoint = props.waypoints.find(waypoint => waypoint.id === props.selectedRoute.to)
    return (
      <Row style={{marginTop: 15}}>
        <Col className='text-left'>
          <div style={{paddingTop: 8, paddingBottom: 8, paddingLeft: 16, paddingRight: 16, border: '1px solid #EAEDF3'}} onClick={() => setShowDestinationModal(true)}>
            <Row>
              <Col xs={1} style={{paddingTop: 6, textAlign: 'center', paddingLeft: 14}}>
                <span style={{fontSize: 24, color: '#DF5364'}} >
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </span>
              </Col>
              <Col xs={11} style={{paddingLeft: 20}}>
                <span style={{fontSize: 14}}>{staticTranslate('destination', props.language)} - {staticTranslate('tapToChange', props.language).toLowerCase()}</span><br />
                <span style={{fontSize: 18}}>{endPoint.name.toUpperCase()}</span>
              </Col>
            </Row>
          </div>
          <div style={{display: 'flex'}}>
            <div onClick={() => reverseRoute()} style={{position: 'relative', top: -96, marginLeft: 'auto', marginRight: '6px', border: '1px solid #EAEDF3', height: 40, width: 40, borderRadius: 20, backgroundColor: 'white', alignItems: 'center', textAlign: 'center', paddingTop: 6}}>
              <span style={{fontSize: 18, color: 'grey'}} >
                <FontAwesomeIcon icon={faRetweet} />
              </span>
            </div>
          </div>
          
        </Col>
      </Row>
    )
  }

  const renderRoute = () => {
    return props.selectedRoute.connections.map((connection, index) => {
      const connectionDetail = props.connections.find(conn => conn.id === connection)
      const fromTemp = props.waypoints?.find(wayp => wayp.id === connectionDetail.from)
      const toTemp = props.waypoints?.find(wayp => wayp.id === connectionDetail.to)
      let backgroundImage = connectionDetail.image;
      let icon = require('../../images/arrow-forward.png')
      if (connectionDetail.icon) {
        icon = require('../../images/' + connectionDetail.icon + '.png')
      }
      return (
        <>
          <Row key={connectionDetail.id}>
            <a name={'pos' + index}></a>
            <Col>
              <div style={{position: 'relative', display: 'block', width: 350, marginRight: 'auto', marginLeft: 'auto', marginTop: 15, marginBottom: 15}}>   
                <div style={{display: 'block', width: 350, height: 466, background: '#EEEEEE', textAlign: 'center'}}>
                  {backgroundImage && (
                    <a href={'#pos' + (index + 1)}><img src={backgroundImage} style={{height: 466, width: 350}}/></a>
                  )}
                  <div style={{position: 'absolute', display: 'block', top: 300, width: 220, height: 220, left: 67}}>
                    <a href={'#pos' + (index + 1)}><img src={icon} style={{width: 180, height: 180 }} /></a>
                  </div>
                </div>
                <div style={{position: 'absolute', display: 'block', top: 10, left: 0, zIndex: 100, paddingLeft: 15, paddingTop: 10, paddingRight: 15}}>
                  <div style={{fontSize: 18, backgroundColor: getPrimaryColor(props.client, props.location), color: 'white', width: 40, height: 40, borderRadius: 20, fontWeight: 'bold', textAlign: 'center', paddingTop: 6}}>
                    {index + 1}
                  </div>
                </div>
                <div style={{display: 'block', fontSize: 18, paddingTop: 15, paddingBottom: 0, textAlign: 'center'}}>
                  <span style={{fontSize: 12}}>({fromTemp.name + ' -> ' + toTemp.name})<br></br></span>
                  {descriptions[index]}<br/><br />
                  <hr />
                  {index + 1 === props.selectedRoute.connections.length && !toTemp.destinationImage && (
                    <span>{staticTranslate('youHaveReachedYourDestination', props.language)}</span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {((index + 1) === props.selectedRoute.connections?.length && toTemp.destinationImage) && (
            <Row key={connectionDetail.id + 'd'}>
              <a name={'pos' + (index + 1)}></a>
              <Col>
                <div style={{position: 'relative', display: 'block', width: 350, marginRight: 'auto', marginLeft: 'auto', marginTop: 15, marginBottom: 15}}>   
                  <div style={{display: 'block', width: 350, height: 466, background: '#EEEEEE', textAlign: 'center'}}>
                    {toTemp.destinationImage && (
                      <img src={toTemp.destinationImage} style={{height: 466, width: 350}}/>
                    )}
                  </div>
                  <div style={{position: 'absolute', display: 'block', top: 10, left: 0, zIndex: 100, paddingLeft: 15, paddingTop: 10, paddingRight: 15}}>
                    <div style={{fontSize: 18, backgroundColor: getPrimaryColor(props.client, props.location), color: 'white', width: 40, height: 40, borderRadius: 20, fontWeight: 'bold', textAlign: 'center', paddingTop: 6}}>
                      {index + 2}
                    </div>
                  </div>
                  <div style={{display: 'block', fontSize: 18, paddingTop: 15, paddingBottom: 0, textAlign: 'center'}}>
                    <span style={{fontSize: 12}}>({fromTemp.name + ' -> ' + toTemp.name})<br></br></span>
                    {descriptions[index]}<br/><br />
                    <hr />
                    {index + 1 === props.selectedRoute.connections.length && (
                      <span>{staticTranslate('youHaveReachedYourDestination', props.language)}</span>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </>
        
        
      )
    })
  }

  const renderFeedback = () => {
    return (
      <div>
        <Row style={{marginTop: 30}}>
          <Col className='text-center'>
            <span style={{fontWeight: 'bold', fontSize: 18}}>{staticTranslate('howEasilyDidYouFindYourDestination', props.language)}</span><br />
          </Col>
        </Row>
        <Row style={{marginTop: 20, paddingLeft: 10, paddingRight: 10, marginBottom: 30}}>
          <Col className='text-center' style={{paddingRight: 0, paddingLeft: 0}}>
            <span style={{fontSize: 50, color: '#68D266'}} onClick={() => props.sendFeedback(5)}>
              <FontAwesomeIcon icon={faSmile} />
            </span>
          </Col>
          <Col className='text-center' style={{paddingRight: 0, paddingLeft: 0}}>
            <span style={{fontSize: 50, color: '#B2D267'}} onClick={() => props.sendFeedback(4)}>
              <FontAwesomeIcon icon={faSmile} />
            </span>
          </Col>
          <Col className='text-center' style={{paddingRight: 0, paddingLeft: 0}}>
            <span style={{fontSize: 50, color: '#FAC702'}} onClick={() => props.sendFeedback(3)}>
              <FontAwesomeIcon icon={faMeh} />
            </span>
          </Col>
          <Col className='text-center' style={{paddingRight: 0, paddingLeft: 0}}>
            <span style={{fontSize: 50, color: '#DF8A52'}} onClick={() => props.sendFeedback(2)}>
              <FontAwesomeIcon icon={faFrown} />
            </span>
          </Col>
          <Col className='text-center' style={{paddingRight: 0, paddingLeft: 0}}>
            <span style={{fontSize: 50, color: '#DF5364'}} onClick={() => props.sendFeedback(1)}>
              <FontAwesomeIcon icon={faFrown} />
            </span>
          </Col>
        </Row>
      </div>
    )
  }

  const renderComment = () => {
    return (
      <div>
        <Row style={{marginTop: 30}}>
          <Col className='text-center'>
            <span style={{fontWeight: 'bold', fontSize: 18}}>{staticTranslate('thankYou', props.language)}<br />
              {staticTranslate('wouldYouLikeToAddAComment', props.language)}
            </span>
          </Col>
        </Row>
        <Row style={{marginTop: 20}}>
          <Col className='text-center'>
            <Form.Control as="textarea" rows={4} onChange={(event) => setComment(event.target.value)} />
          </Col>
        </Row>
        <Row style={{marginTop: 20, marginBottom: 30}}>
          <Col className='text-center'>
            <Button style={{
                width: 160,
                height: 60,
                borderRadius: 5,
                textAlign: 'center',
                backgroundColor: getPrimaryColor(props.client, props.location),
                alignSelf: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'white',
                border: 'none'
              }}
              disabled={!comment}
              onClick={() => props.sendComment(comment)}
            >
              {staticTranslate('submit', props.language).toUpperCase()}
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  const renderThankYou = () => {
    return (
      <div>
        <Row style={{marginTop: 30, marginBottom: 30}}>
          <Col className='text-center'>
            <span style={{fontWeight: 'bold', fontSize: 18}}>{staticTranslate('thankYouAgain', props.language)}<br />{staticTranslate('yourFeedbackIsVeryImportantToUs', props.language)}
            </span>
          </Col>
        </Row>
      </div>
    )
  }

  const renderBottomButtons = () => {
    return (
      <div>
        <Row style={{marginTop: 30, marginBottom: 30, justifyContent: 'space-between'}}>
          <Col style={{paddingRight: 0}}>
            <div onClick={() => {
                reverseRoute()
                window.scroll(0, -50)
              }} style={{width: 160, height: 60, border: '1px solid #EAEDF3', marginLeft: 'auto', marginRight: 'auto'}}>
              <Row>
                <Col xs={3} style={{paddingTop: 13, paddingLeft: 24}}>
                  <span style={{fontSize: 20, color: 'grey'}}>
                    <FontAwesomeIcon icon={faRetweet} />
                  </span>
                </Col>
                <Col xs={9} style={{paddingTop: 16, paddingLeft: 18}}>
                  <span style={{fontSize: 14}}>Reverse route</span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col style={{paddingLeft: 0}}>
            <div onClick={() => handleOnShareClick()} style={{width: 160, height: 60, border: '1px solid #EAEDF3', marginLeft: 'auto', marginRight: 'auto'}}>
              <Row>
                <Col xs={3} style={{paddingTop: 13, paddingLeft: 26}}>
                  <span style={{fontSize: 20, color: 'grey'}}>
                    <FontAwesomeIcon icon={faShareAlt} />
                  </span>
                </Col>
                <Col xs={9} style={{paddingTop: 16, paddingLeft: 20}}>
                  <span style={{fontSize: 14}}>Share route</span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  if (props.loadingLocation || !props.routes || props.routes.length === 0) {
    return (
      <div>
        <Row style={{marginTop: 100}}>
          <Col className='text-center'>
            <FontAwesomeIcon icon={faSpinner} spin size={'4x'} />
          </Col>
        </Row>
        <div className='fixed-bottom'>
          <Footer />
        </div>
      </div>
    )
  }

  if (props.selectedRoute && props.selectedRoute.id) {
    return (
      <div>
        <Header
          accessibility={props.accessibility}
          setAccessibility={props.setAccessibility}
          primaryColor={getPrimaryColor(props.client, props.location)}
          language={props.language}
          setLanguage={props.setLanguage}
          client={props.client}
        />
          {props.location && props.location.logoOnRoute && (
            <Logo location={props.location} client={props.client} />
          )}
          {renderStartPoint()}
          {renderEndPoint()}
          {renderRouteDescription()}
          {!noAccessibleRoute && renderRoute()}
          {!noAccessibleRoute && renderBottomButtons()}
          {!noAccessibleRoute && !props.sentFeedback && renderFeedback()}
          {!props.sentComment && props.sentFeedback && renderComment()}
          {props.sentComment && props.sentFeedback && renderThankYou()}
          <SelectDestinationModal
            show={showStartingModal}
            onHide={() => setShowStartingModal(false)}
            waypoints={props.waypoints}
            onSelect={setRoute}
            routes={props.routes}
            currentWaypoint={props.selectedRoute.to}
            currentFrom={props.selectedRoute.from}
            accessibility={props.accessibility}
            target={'from'}
            language={props.language}
            location={props.location}
            getRoutes={props.getRoutes}
          />
          <SelectDestinationModal
            show={showDestinationModal}
            onHide={() => setShowDestinationModal(false)}
            waypoints={props.waypoints}
            onSelect={setRoute}
            routes={props.routes}
            currentWaypoint={props.selectedRoute.from}
            accessibility={props.accessibility}
            target={'to'}
            language={props.language}
            location={props.location}
            getRoutes={props.getRoutes}
          />
        <Footer />
      </div>
    )
  }

  return null
}

export default Route;
