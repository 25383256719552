// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  accessibility: false,
  language: 'US'
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.SET_ACCESSIBILITY:
      return {
        ...state, accessibility: action.payload,
      };
    case actionConstants.SET_LANGUAGE:
      return {
        ...state, language: action.payload,
      };  
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getGeneral = ({
  accessibility,
  language
}) => ({
  accessibility,
  language
});
