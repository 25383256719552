import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {WEBSITE_URL} from '../environmentVariables';

const waysnapLogo = require('../images/waysnap.png')
const oobeoLogo = require('../images/oobeo-logo.png')

const Footer = () => {
  return (
    <div style={{height: 110, fontSize: 12, color: 'lightgrey'}}>
      <Row>
        <Col className='text-center'>
          {WEBSITE_URL.indexOf('park-nav') === -1 && (
            <a href={WEBSITE_URL} target='_blank' style={{textDecoration: 'none'}}>
              <img src={waysnapLogo} style={{height: 76}} />
            </a>
          )}
          {WEBSITE_URL.indexOf('park-nav') !== -1 && (
            <a href={'https://oobeo.com'} target='_blank' style={{textDecoration: 'none'}}>
              <img src={oobeoLogo} style={{height: 34}} />
            </a>
          )}
        </Col>
      </Row>
      <Row>
        <Col style={{textAlign: 'right'}}><a href={WEBSITE_URL + '/privacy'} target='_blank' style={{textDecoration: 'none', color: 'lightgrey'}}>Privacy policy</a></Col>
        <Col style={{textAlign: 'left'}}><a href={WEBSITE_URL + '/terms'} target='_blank'style={{textDecoration: 'none', color: 'lightgrey'}}>Terms of use</a></Col>
      </Row>
    </div>
  )
}

export default Footer;