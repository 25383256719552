import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Logo = (props) => {
  let location = props.location;
  return (
    <Row style={{marginTop: 20}}>
        <Col className='text-center'>
          {location && location.logo ? (
            <img src={location.logo} style={{maxHeight: 100, maxWidth: 300}} />
          ) : props.client && props.client.logo ? (
            <img src={props.client.logo} style={{maxHeight: 100, maxWidth: 300}} />
          ) : <div>&nbsp;</div>}
        </Col>
      </Row>
  )
}

export default Logo;