import * as actionConstants from '../actionConstants';
import { db } from '../App';
import { collection, collectionGroup, query, where, getDocs, getDoc, doc, setDoc, increment, deleteDoc, addDoc } from "firebase/firestore";
import { setLanguage } from './generalActions';

const moment = require('moment');

export const getLocationByCode = locationCode => async dispatch => {
  dispatch({ type: actionConstants.LOADING_LOCATION });

  const locations = query(collectionGroup(db, 'locations'), where('locationCode', '==', locationCode));
  const querySnapshot = await getDocs(locations)

  if (querySnapshot.size === 1) {
    let docResult = querySnapshot.docs[0]
    let waypointCollection = collection(db, 'clients', docResult.ref.parent.parent.id, 'locations', docResult.id, 'waypoints');
    let connectionCollection = collection(db, 'clients', docResult.ref.parent.parent.id, 'locations', docResult.id, 'connections');
    //let routeCollection = collection(db, 'clients', docResult.ref.parent.parent.id, 'locations', docResult.id, 'routes');
    let clientRef = doc(db, 'clients', docResult.ref.parent.parent.id)
    let client = await getDoc(clientRef)
    let waypoints = [];
    let connections = [];
    let routes = [];
    let subQuerySnapshot = await getDocs(waypointCollection)
    subQuerySnapshot.docs.map(doc => {
      waypoints.push({id: doc.id, ...doc.data()})
    })
    subQuerySnapshot = await getDocs(connectionCollection)
    subQuerySnapshot.docs.map(doc => {
      connections.push({id: doc.id, ...doc.data()})
    })
    // subQuerySnapshot = await getDocs(routeCollection)
    // subQuerySnapshot.docs.map(doc => {
    //   routes.push({id: doc.id, ...doc.data()})
    // })
    dispatch({ 
      type: actionConstants.LOADED_LOCATION,
      location: {id: docResult.id, ...docResult.data()},
      waypoints: waypoints,
      client: {id: client.id, ...client.data()},
      connections: connections,
      //routes: routes,
    })
    if (client.data().language) {
      dispatch(setLanguage(client.data().language))
    }
    
  }

};

export const getRoutes = (waypoint, secondWaypoint) => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_ROUTES})
  let routeCollection = collection(db, 'clients', getState().location.client?.id, 'locations', getState().location.location?.id, 'routes');
  let q = query(routeCollection, where("from", "==", waypoint));
  let querySnapshot = await getDocs(q);
  let routes = []
  querySnapshot.forEach((doc) => {
    routes.push({id: doc.id, ...doc.data()})
  });
  q = query(routeCollection, where("to", "==", waypoint));
  querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    routes.push({id: doc.id, ...doc.data()})
  });
  if (secondWaypoint) {
    q = query(routeCollection, where("from", "==", secondWaypoint));
    querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      routes.push({id: doc.id, ...doc.data()})
    });
    q = query(routeCollection, where("to", "==", secondWaypoint));
    querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      routes.push({id: doc.id, ...doc.data()})
    });
  }
  dispatch({ type: actionConstants.LOADED_ROUTES, payload: routes})
}

export const incrementScannedCount = waypointId => async (dispatch, getState) => {
  if (getState().location && getState().location.client && getState().location.client.id && getState().location.location && getState().location.location.id) {
    const waypointRef = doc(db, 'clients', getState().location.client.id, 'locations', getState().location.location.id, 'waypoints', waypointId);
    await setDoc(waypointRef, {scannedCount: increment(1)}, { merge: true }).catch(error => console.log(error))
  }
}

export const autoGenerateRoutes = () => async (dispatch, getState) => {
  let waypoints = getState().location.waypoints;
  let connections = getState().location.connections;
  let routes = getState().location.routes;
  let generatedRoutes = [];
  let generatedRoutesTotal = [];
  let copyOfGeneratedRoutes = [];

  if (connections && connections.length > 0) {
    generatedRoutes = generateInitialRoutes(connections)
    generatedRoutesTotal.push(...generatedRoutes)

    while (generatedRoutes.length > 0) {
      console.log(generatedRoutes.length)
      copyOfGeneratedRoutes = JSON.parse(JSON.stringify(generatedRoutes))
      generatedRoutes = generateNewRoutes(copyOfGeneratedRoutes, connections, generatedRoutesTotal)
      generatedRoutesTotal.push(...generatedRoutes)
    }

    console.log('Total routes: ', generatedRoutesTotal)
    
    const routeCollection = collection(db, 'clients', getState().location.client.id, 'locations', getState().location.location.id, 'routes');
    const dbRoutes = await getDocs(routeCollection)
    dbRoutes.forEach(async document => {
      await deleteDoc(doc(db, 'clients', getState().location.client.id, 'locations', getState().location.location.id, 'routes', document.id))
    })

    generatedRoutesTotal.map(async route => {
      await addDoc(collection(db, 'clients', getState().location.client.id, 'locations', getState().location.location.id, 'routes'), route)
    })
  }
}

const generateInitialRoutes = connections => {
  let newRoutes = [];
  connections.map(connection => {
    let tempConnection = JSON.parse(JSON.stringify(connection))
    newRoutes.push({
      from: tempConnection.from,
      to: tempConnection.to,
      time: tempConnection.time,
      accessibility: tempConnection.accessibility,
      connections: [tempConnection.id],
      prior: null,
    })
  })
  return newRoutes
}

const generateNewRoutes = (iterableRoutes, connections, generatedRoutesTotal) => {
  let newRoutes = [];
  iterableRoutes.map(route => {
    const tempRoute = JSON.parse(JSON.stringify(route))
    const tempRouteConnections = JSON.parse(JSON.stringify(tempRoute.connections))
    connections.filter(connection => connection.from === tempRoute.to && connection.to !== tempRoute.from && connection.to !== tempRoute.prior).map(connection => {
      const tempConnection = JSON.parse(JSON.stringify(connection))
      let newConnections = []
      newConnections =  JSON.parse(JSON.stringify(tempRouteConnections))
      newConnections.push(tempConnection.id)
      
      let existing = generatedRoutesTotal.find(route => route.from === tempRoute.from && route.to === tempConnection.to)
      
      if (!existing) {
        newRoutes.push({
          from: tempRoute.from,
          to: tempConnection.to,
          time: tempRoute.time + tempConnection.time,
          accessibility: !tempRoute.accessibility ? false : tempConnection.accessibility,
          connections: newConnections,
          prior: tempRoute.to
        })
      } else {
        console.log(tempRoute.from, tempConnection.to)
      }
    })
  })
  return (newRoutes)
}

export const setSelectedRoute = route => async dispatch => {
  dispatch({ type: actionConstants.SET_SELECTED_ROUTE, payload: route });
};

export const incrementRouteCount = route => async (dispatch, getState) => {
  if (!getState().location.chosenRouteId && getState().location && getState().location.location.id && getState().location.client && getState().location.client.id) {
    let docRef = await addDoc(collection(db, 'clients', getState().location.client.id, 'locations', getState().location.location.id, 'chosenRoutes'), {...route, time: moment().toISOString()})
    await setDoc(doc(db, 'chosenRoutesAll', docRef.id), {...route, time: moment().toISOString(), timeStamp: moment().unix(), client: getState().location.client.id, location: getState().location.location.id})
    dispatch(incrementRouteStartCount(route.from))
    dispatch(incrementRouteEndCount(route.to))
    dispatch({ type: actionConstants.SET_CHOSEN_ROUTE, payload: docRef.id})
  }
}

export const incrementRouteStartCount = waypointId => async (dispatch, getState) => {
  const waypointRef = doc(db, 'clients', getState().location.client.id, 'locations', getState().location.location.id, 'waypoints', waypointId);
  await setDoc(waypointRef, {routeStartCount: increment(1)}, { merge: true }).catch(error => console.log(error))
}

export const incrementRouteEndCount = waypointId => async (dispatch, getState) => {
  const waypointRef = doc(db, 'clients', getState().location.client.id, 'locations', getState().location.location.id, 'waypoints', waypointId);
  await setDoc(waypointRef, {routeEndCount: increment(1)}, { merge: true }).catch(error => console.log(error))
}

export const sendFeedback = feedback => async (dispatch, getState) => {
  let docRef = doc(db, 'clients', getState().location.client.id, 'locations', getState().location.location.id, 'chosenRoutes', getState().location.chosenRouteId);
  await setDoc(docRef, { feedback: feedback }, { merge: true }).catch(error => console.log(error))
  await setDoc(doc(db, 'chosenRoutesAll', docRef.id), { feedback: feedback }, { merge: true }).catch(error => console.log(error))
  dispatch({ type: actionConstants.SENT_FEEDBACK})
}

export const sendComment = comment => async (dispatch, getState) => {
  let docRef = doc(db, 'clients', getState().location.client.id, 'locations', getState().location.location.id, 'chosenRoutes', getState().location.chosenRouteId);
  await setDoc(docRef, { comment: comment }, { merge: true }).catch(error => console.log(error))
  await setDoc(doc(db, 'chosenRoutesAll', docRef.id), { comment: comment }, { merge: true }).catch(error => console.log(error))
  dispatch({ type: actionConstants.SENT_COMMENT})
}

export const setVisit = (waypoint) => async (dispatch, getState) => {
  if (getState().location && getState().location.location && getState().location.client && getState().location.location.id && getState().location.client.id) {
    let docRef = await addDoc(collection(db, 'visits'), {location: getState().location.location.id, client: getState().location.client.id, waypoint: waypoint, timestamp: moment().toDate(), timeStamp_unix: moment().unix()})
  }
}
