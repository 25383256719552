import LocalizedStrings from 'react-localization';
import { textStrings } from '../constants';

export const autoTranslate = async (text, to) => {
  if (to === 'US') {
    return text
  }

  let fromLang = 'en';
  let toLang = to.toLowerCase(); // translate to norwegian

  const API_KEY = ['AIzaSyA91I_nBGYj1j4Ca9pSCOyKTTsyR4kqzDg'];

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
  url += '&q=' + encodeURI(text);
  url += `&source=${fromLang}`;
  url += `&target=${toLang}`;

  let result = await getTranslation(url).then(data => data)

  return result || text
}

const getTranslation = (url) => {
  return fetch(url, { 
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  })
  .then(res => res.json())
  .then((response) => {
    //console.log("response from google: ", response);
    if (response && response.data && response.data.translations.length > 0) {
      return response.data.translations[0].translatedText
    }
    return null;
  })
  .catch(error => {
    console.log("There was an error with the translation request: ", error);
  })
}

export const staticTranslate = (text, to) => {
  if (!to || to.toLowerCase() === 'us') {
    to = 'en_us'
  }
  if (to.toLowerCase() === 'gb') {
    to = 'en_gb'
  }
  let strings = new LocalizedStrings(textStrings)
  strings.setLanguage(to.toLowerCase())
  return strings[text]
}

export const getPrimaryColor = (client, location) => {
  if (location && location.primaryColor) {
    return location.primaryColor
  }
  if (client && client.primaryColor) {
    return client.primaryColor
  }
  return '#000000';
}
