import Route from "./Route";
import { ActionCreators } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLocation, getGeneral } from "../../reducers/rootReducer";

const mapStateToProps = state => {
  const { location, waypoints, routes, connections, selectedRoute, chosenRouteId, sentFeedback, sentComment, client, loadingLocation, loadedLocation} = getLocation(state);
  const { accessibility, language } = getGeneral(state);

  return {
    location,
    selectedRoute,
    waypoints,
    routes,
    connections,
    accessibility,
    chosenRouteId,
    sentFeedback,
    sentComment,
    language,
    client,
    loadingLocation,
    loadedLocation
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Route);