import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { logEvent } from "firebase/analytics";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";
import SelectDestinationModal from '../../components/SelectDestinationModal';
import { analytics } from '../../App';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { autoTranslate, staticTranslate, getPrimaryColor } from '../../Tools';
import Logo from '../../components/Logo';

const moment = require('moment')

const Welcome = (props) => {

  const {locationCode, waypointCode} = useParams();
  const [showDestinationModal, setShowDestinationModal] = useState(false);
  const [location, setLocation] = useState(props.location);
  const [currentWaypoint, setCurrentWaypoint] = useState(null);
  const [destinationWaypoint, setDestinationWaypoint] = useState(null);

  useEffect(() => {
    props.getLocationByCode(locationCode);
  }, [])

  useEffect(() => {
    let getTranslations = async () => {
      //setWelcomeText(await translate('Welcome to', props.language).then(text => text))
    }
    
    //getTranslations()
  }, [props.language])

  useEffect(() => {
    if (props.location && props.location.id) {
      setLocation(props.location)
      document.title = props.location.name + ' | Waysnap'
      if (waypointCode && props.waypoints && props.waypoints.length > 0) {
        let waypoint = props.waypoints.find(waypoint => waypoint.waypointCode === waypointCode);
        setCurrentWaypoint(waypoint)
        props.incrementScannedCount(waypoint.id)
        props.setVisit(waypoint.id)
        logEvent(analytics, 'Waypoint scanned', {location_name: props.location.name, location_id: props.location.id, waypoint_name: waypoint.name, waypoint_id: waypoint.id, time: moment().toISOString()});
      }
    }
  }, [props.location, props.waypoints])

  useEffect(() => {
  }, [currentWaypoint])

  const setDestinationAndRoute = (waypoint, route) => {
    setDestinationWaypoint(waypoint)
    props.setSelectedRoute(route)
  }

  const renderLogo = () => {
    return (
      <Logo location={location} client={props.client} />
    )
  }

  const renderDescription = () => {
    return (
      <Row style={{marginTop: 30}}>
        <Col className='text-center'>
          <span>{staticTranslate('welcomeTo', props.language)} {location.name}.</span><br />
          {currentWaypoint && currentWaypoint.id && (
            <span>{staticTranslate('youAreAt', props.language)} {currentWaypoint.name}.</span>
          )}
        </Col>
      </Row>
    )
  }

  const renderSelectDestination = () => {
    return (
      <div style={{marginTop: 20}}>
        <Row>
          <Col className='text-center'>
            <span style={{fontSize: 20, fontWeight: 'bold'}}>{staticTranslate('whereWouldYouLikeToGo', props.language)}</span>
          </Col>
        </Row>
        <Row style={{marginTop: 16}}>
          <Col className='text-center' style={{paddingLeft: 36, paddingRight: 36}}>
            <Form>
              <div
                className='form-control'
                onClick={() => setShowDestinationModal(true)}
              >{destinationWaypoint && destinationWaypoint.id ? destinationWaypoint.name.toUpperCase() : staticTranslate('selectDestination', props.language)}</div>
            </Form>
          </Col>
        </Row>
        <Row style={{marginTop: 20}}>
          <Col className='text-center'>
            <Button style={{
                width: 160,
                height: 60,
                borderRadius: 5,
                textAlign: 'center',
                backgroundColor: getPrimaryColor(props.client, props.location),
                alignSelf: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                color: 'white',
                border: 'none'
              }}
              disabled={!destinationWaypoint}
              onClick={() => window.location.href = '/route/' + props.location.locationCode + '/' + currentWaypoint.waypointCode + '/' + destinationWaypoint.waypointCode}
            >
              {staticTranslate('start', props.language).toUpperCase()}
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  if (props.loadingLocation || !props.routes || !props.location.id || !currentWaypoint || !currentWaypoint.id) {
    return (
      <div>
        <Row style={{marginTop: 100}}>
          <Col className='text-center'>
            <FontAwesomeIcon icon={faSpinner} spin size={'4x'} />
          </Col>
        </Row>
        <div className='fixed-bottom'>
          <Footer />
        </div>
      </div>
    )
  }

  if (props.location && props.location.id && props.location.name && currentWaypoint && currentWaypoint.id) {
    return (
      <div>
        <Header
          accessibility={props.accessibility}
          setAccessibility={props.setAccessibility}
          primaryColor={getPrimaryColor(props.client, props.location)}
          language={props.language}
          setLanguage={props.setLanguage}
          client={props.client}
        />
        {renderLogo()}
        {renderDescription()}
        {renderSelectDestination()}
        <div className='fixed-bottom' style={{marginTop: 350, position: 'relative'}}>
          <Footer />
        </div>
        <SelectDestinationModal
          show={showDestinationModal}
          onHide={() => setShowDestinationModal(false)}
          waypoints={props.waypoints}
          onSelect={setDestinationAndRoute}
          routes={props.routes}
          currentWaypoint={currentWaypoint?.id}
          accessibility={props.accessibility}
          target={'to'}
          language={props.language}
          location={props.location}
          loadingRoutes={props.loadingRoutes}
          loadedRoutes={props.loadedRoutes}
          getRoutes={props.getRoutes}
        />
      </div>
    )
  } else {
    return null
  }
  
}

export default Welcome;
