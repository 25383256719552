import Welcome from "./Welcome";
import { ActionCreators } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLocation, getGeneral } from "../../reducers/rootReducer";

const mapStateToProps = state => {
  const { location, loadingLocation, loadedLocation, waypoints, routes, connections, client, loadedRoutes, loadingRoutes } = getLocation(state);
  const { accessibility, language } = getGeneral(state);

  return {
    location,
    loadingLocation,
    loadedLocation,
    waypoints,
    routes,
    connections,
    accessibility,
    language,
    client,
    loadedRoutes,
    loadingRoutes,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Welcome);