import React from 'react';

const FilterButton = ({label, selected, onClick, location}) => {
  return (
    <div
      onClick={() => onClick(label)}
      style={{
        height: 42,
        paddingTop: 11,
        borderRadius: 21,
        border: selected ? '1px solid ' + location.primaryColor : '1px solid lightgrey',
        fontSize: 12,
        marginRight: 6,
        backgroundColor: selected ? location.primaryColor : 'white',
        minWidth: 102,
        textAlign: 'center',
        color: selected ? '#FFFFFF' : '#212529',
      }}
    >{label}</div>
  )
}

export default FilterButton;