import React from "react";
import { Navigate } from "react-router-dom";

// Layout Types
import DefaultLayout from "./layouts/Default";

import Welcome from "./containers/Welcome/index";
import Route from "./containers/Route/index";

export default [
  {
    path: "/:locationCode/:waypointCode",
    exact: true,
    layout: DefaultLayout,
    //component: () => <Navigate to="/properties" />
    component: Welcome
  },
  {
    path: "/:locationCode",
    exact: true,
    layout: DefaultLayout,
    component: Welcome
  },
  {
    path: "/route/:locationCode/:fromCode/:toCode",
    exact: true,
    layout: DefaultLayout,
    component: Route
  },
  {
    path: "/route",
    exact: true,
    layout: DefaultLayout,
    component: Route
  },
];
