import React, { useState, useEffect, useRef } from 'react';
import { faDoorOpen, faRestroom, faUtensils, faWheelchair, faTimes, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Container, FormLabel, FormControl, Form, Col } from 'react-bootstrap';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { staticTranslate } from '../Tools';
import CustomModal from './CustomModal';
import FilterButton from './FilterButton';
import Footer from './Footer';
const moment = require('moment')
const algoliasearch = require('algoliasearch')
const client = algoliasearch('YLXZEVKXMN', '2a7ec9108c48129150285007f1eb8eee')
const chosenRoutesIndex = client.initIndex('chosenRoutes')


const SelectDestinationModal = ({show, onHide, waypoints, onSelect, routes, currentWaypoint, accessibility, target, language, location, loadingRoutes, loadedRoutes, getRoutes, currentFrom}) => {

  const [searchTerm, setSearchTerm] = useState('')
  const [results, setResults] = useState([])
  const [filter, setFilter] = useState(null)
  const [initialResults, setInitialResults] = useState(waypoints.filter(waypoint => currentWaypoint && waypoint.searchable && waypoint.id !== currentWaypoint).sort((a, b) => a.name.localeCompare(b.name)))
  const [popularResults, setPopularResults] = useState([])
  const modalRef = useRef();

  useEffect(() => {
    const mostPopularDestinations = async () => {
      setPopularResults(await getMostPopularDestinations())
    }
    mostPopularDestinations()
  }, [])

  useEffect(() => {
    if (currentWaypoint && target) {
      if (target === 'to') {
        getRoutes(currentWaypoint)
      }
      if (target === 'from') {
        getRoutes(currentFrom, currentWaypoint)
      }
    }
  }, [currentWaypoint, target])

  useEffect(() => {
    
    setInitialResults(waypoints.filter(waypoint => currentWaypoint && waypoint.searchable && waypoint.id !== currentWaypoint).sort((a, b) => a.name.localeCompare(b.name)))
    let results = waypoints.filter(waypoint => currentWaypoint && waypoint.searchable && waypoint.id !== currentWaypoint).sort((a, b) => a.name.localeCompare(b.name));
    let unsortedResults = results
    let splicedResultsByPopularity = unsortedResults.filter(result => popularResults.find(spResult => spResult.id === result.id))
    let unpopularResults = results.filter(result => splicedResultsByPopularity.map(spResult => spResult.id).indexOf(result.id) === -1)
    splicedResultsByPopularity = splicedResultsByPopularity.map(result => ({...result, popular: true, count: popularResults.find(pResult => pResult.id === result.id).count}))
    results = splicedResultsByPopularity.sort((a, b) => b.count - a.count).concat(unpopularResults.sort((a, b) => a.name.localeCompare(b.name)))
    if (searchTerm && searchTerm.length > 2) {
      results = results.filter(waypoint => waypoint.searchable && ((waypoint.name && waypoint.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) || (waypoint.additionalInformation && waypoint.additionalInformation.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
    }
    if (filter) {
      let searchFilter = filter
      if (filter === 'Toilets' || filter === 'Baños') {
        searchFilter = 'Restrooms'
      }
      results = results.filter(waypoint => waypoint.category === searchFilter)
    }
    
    setResults(results)
  }, [searchTerm, filter, waypoints, routes, currentWaypoint, popularResults])

  const getMostPopularDestinations = async () => {
    let startDate = moment().subtract(30, 'days').unix()
    let endDate = moment().unix()
    let chosenRoutesToArray = []

    let filter = 'timeStamp > ' + startDate + ' AND timeStamp < ' + endDate + ' AND location:' + location.id

    let chosenRoutes = await chosenRoutesIndex.search('', {
      filters: filter,
      facets: ['to'],
    }).then(hits => {
      return hits.facets.to
    });

    if (chosenRoutes) {
      chosenRoutesToArray = Object.keys(chosenRoutes).map(key => ({id: key, count: chosenRoutes[key]})).sort((a, b) => b.count - a.count).splice(0, 2)
    }

    return chosenRoutesToArray;
  }

  const handleSearchInput = searchTerm => {
    setSearchTerm(searchTerm)
  }

  const handleFilterClick = label => {
    if (label === filter) {
      setFilter(null)
    } else {
      setFilter(label)
    }
  }

  const renderResults = () => {
    if (routes?.length === 0) {
      return (<div>No routes yet</div>)
    }
    return results.map(waypoint => {
      let route;
      if (target === 'to') {
        route = routes.find(route => route.from === currentWaypoint && route.to === waypoint.id)
      } else {
        route = routes.find(route => route.to === currentWaypoint && route.from === waypoint.id)
      }
      
      let icon = require('../images/waysnap-pin-grey.png')
      if (waypoint.logo) {
        icon = waypoint.logo
      }
      if (route && (!accessibility || route.accessibility === accessibility)) {
        return (
          <Row key={waypoint.id} style={{marginTop: 4}} onClick={() => { onSelect(waypoint, route); onHide(); }}>
            {location.logoOnDestinations && (
              <Col xs={1} style={{padding: 0, paddingLeft: 0}}>
                <img src={icon} style={{width: 30, height: 30 }} />
              </Col>
            )}
            <Col xs={location.logoOnDestinations ? 11 : 12}>
              <p style={{marginBottom: 4, lineHeight: '12px'}}>{waypoint.name.toUpperCase()}
                {waypoint.category === 'Exit' && (<FontAwesomeIcon icon={faDoorOpen} style={{marginLeft: 8, color: 'darkgrey'}} />)}
                {waypoint.category === 'Restrooms' && (<FontAwesomeIcon icon={faRestroom} style={{marginLeft: 8, color: 'darkgrey'}} />)}
                {waypoint.category === 'Food & Drink' && (<FontAwesomeIcon icon={faUtensils} style={{marginLeft: 8, color: 'darkgrey'}} />)}
                {waypoint.popular && (
                  <div style={{display: 'inline-block', marginLeft: 8, paddingLeft: 10, paddingRight: 10, paddingBottom: 4, borderRadius: 5, fontSize: 12, backgroundColor: '#D6BD6D', color: 'white'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <div style={{paddingTop: 4}}>
                        <FontAwesomeIcon icon={faStar} style={{marginRight: 8, color: 'white', fontSize: 10}} />
                      </div>
                      <div style={{paddingTop: 5, paddingBottom: 2}}>
                        Popular
                      </div>
                    </div>
                  </div>
                )}
                {/*route.accessibility && (<FontAwesomeIcon icon={faWheelchair} style={{marginLeft: 8, color: 'green'}} />)*/}
              </p>
              <p style={{fontSize: 12, color: 'darkgrey'}}>{route.time} {staticTranslate('minutesAway', language).toLowerCase()}</p>
            </Col>
          </Row>
        )
      } else {
        return null
      }
    })
  }

  const renderQuickFilter = () => {
    return (
      <div>
        <Row>
          <Col>
            <span>{staticTranslate('quickFilter', language)}</span>
          </Col>
        </Row>
        <Row style={{marginTop: 16}}>
          <Col style={{display: 'flex', flexDirection: 'row'}}>
            <FilterButton label={staticTranslate('restrooms', language)} onClick={handleFilterClick} selected={filter === staticTranslate('restrooms', language)} location={location} />
            <FilterButton label={'Food & Drink'} onClick={handleFilterClick} selected={filter === 'Food & Drink'} location={location} />
            <FilterButton label={'Exit'} onClick={handleFilterClick} selected={filter === 'Exit'} location={location} />
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <CustomModal show={show} onHide={onHide} language={language}>
      <Container>
          <Row style={{marginBottom: 30, marginTop: 0}}>
            <Col xs={10}>
              <div>
                <FormControl
                  type='text'
                  placeholder=''
                  //value={searchTerm}
                  autoFocus
                  //onFocus={() => disableBodyScroll(document.querySelector('#mainBody'))}
                  onBlur={() => enableBodyScroll(document.querySelector('#mainBody'))}
                  onChange={(event) => handleSearchInput(event.target.value)}
                />
              </div>
            </Col>
            <Col xs={2}>
              <div onClick={onHide} style={{width: '100%', justifyContent: 'center', paddingTop: 3, textAlign: 'right'}}>
                <FontAwesomeIcon icon={faTimes} style={{fontSize: 30}} />
              </div>
            </Col>
          </Row>
          {renderQuickFilter()}
          {results && results.length > 0 && (
            <Row style={{marginTop: 30, marginBottom: 20}}>
              <Col>
                <span>{staticTranslate('tapToSelect', language)}</span>
              </Col>
            </Row>
          )}
          {renderResults()}
          <Footer />
        </Container>
    </CustomModal>
  )
}

export default SelectDestinationModal;